import React, { useState, useEffect, memo } from "react";
import { HorizontalView } from "./view/HorizontalView";
import { VerticalView } from "./view/VerticalView";
import moment from "moment/moment";

export const expectedDate = (earlyDate, lateDate) => {
    const early = moment(earlyDate);
    const late = moment(lateDate);
    let qualifier = "";
    let expectedDate = [];
    let qualifierStyle = {};

    if (early.isValid() && !late.isValid())
        qualifier = "later than";
    else if (!early.isValid() && late.isValid())
        qualifier = "within";
    else if (early.isValid() && late.isValid() && early.isSame(late)) {
        qualifier = "at";
        qualifierStyle = { color: 'red', fontWeight: 'bold' };
    } else if (early.isValid() && late.isValid() && !early.isSame(late))
        if (early.isAfter(late))
            qualifier = "";
        else
            qualifier = "between";

    if (qualifier === "later than" || qualifier === "at")
        expectedDate = [`${early.format("DD/MM/yyyy")}`, qualifier, `${early.format("HH:mm")}`];
    else if (qualifier === "within")
        expectedDate = [`${late.format("DD/MM/yyyy")}`, qualifier, `${late.format("HH:mm")}`];
    else if (qualifier === "between")
        expectedDate = [`${early.format("DD/MM/yyyy")}`, qualifier, `${early.format("HH:mm")}`, "-", `${late.format("HH:mm")}`];
    else if (qualifier === "")
        expectedDate = [`${early.format("DD/MM/yyyy")}`];

    return (
        <div>
            {expectedDate.map((word, index) => word === 'at' ?
                <span key={index} style={qualifierStyle}>{word} </span> : word + ' ')}
        </div>);
};

export const OrderProgressRequested = memo(({ toId, callAPI, orderProgressRequested, setOrderProgressRequested, horizontalViewActive, isToCustomer, isLoadRequest, suData }) => {
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        setOrderProgressRequested(callAPI);
    }, [toId]);

    useEffect(() => {
        if (orderProgressRequested.length == 0) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    }, [orderProgressRequested]);

    return (
        <>
            {(isLoadRequest && (!orderProgressRequested || orderProgressRequested.length == 0)) ?
                <h6 style={{ margin: '6rem' }}>Currently, no information about actual are available in the system. Once new information are available, they will be shown in the page.</h6> : (
                    horizontalViewActive
                        ?
                        <HorizontalView data={orderProgressRequested.length > 0 ? orderProgressRequested : null} noData={noData} suData={suData} isToCustomer={isToCustomer} />
                        :
                        <VerticalView data={orderProgressRequested} isToCustomer={isToCustomer} />)
            }
        </>
    );
});